<template>
	<div>
		<h2 v-focus tabindex="0" class="big-title">校對進度</h2>
		<tab :headinglevel="3" :data="data">
			<template #table="props">
				<table-div :datas="props.item.datas" :header="props.item.header">
					<template #inactive_book_action="props">
						<button
							class="btn btn-outline-secondary"
							@click="book_del(props.item.ISBN)"
						>
							刪除
						</button>
					</template>
					<template #active_book_action="props">
						<button
							class="btn btn-outline-secondary send_button"
							@click="
								setPriorityPk = props.item.ISBN;
								setPriorityData.priority = props.item.priority;
								setPriorityModalShow = true;
							"
						>
							設定權重
						</button>
					</template>
				</table-div>
			</template>
			<template #bookinfo_repository_table="props">
				<bookinfo-repository :datas="props.item.datas" />
			</template>
			<template #search="props">
				<form-filter>
					<form-item>
						<input
							@keyup.enter="search()"
							v-model="search_value"
							class="form-control"
							type="text"
							placeholder="輸入欲查詢資訊"
						/>
					</form-item>
					<form-item>
						<button type="button" class="btn btn-primary" @click="search()">
							搜尋
						</button>
						<span class="book_search_result" style="margin-left: 0.5em"
							>共查到 {{ data[3].data.length }} 筆資料</span
						>
					</form-item>
				</form-filter>
				<table-div :datas="props.item.datas" :header="props.item.header" />
			</template>
		</tab>
		<modal
			v-if="setPriorityModalShow"
			@closeModal="setPriorityModalShow = false"
		>
			<template slot="header">
				<h4 class="modal-title">權重設定</h4>
			</template>
			<template slot="body">
				<div class="text-center">*權重設定，最高: 1，最低: 9</div>
				<div class="form-horizontal">
					<template v-for="v in ['priority']">
						<formdrf
							:model_info="setPriorityModel[v]"
							:field="v"
							v-model="setPriorityData[v]"
							:key="v"
						></formdrf>
					</template>
				</div>
			</template>
			<template slot="footer">
				<button class="btn btn-outline-secondary" @click="setPriority">
					送出
				</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { bookRest, bookInfoRest } from "@/api/ebookSystem.js";
import formdrf from "@/components/form.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import modal from "@/components/popup/modal.vue";
import tab from "@/components/tab.vue";
import tableDiv from "@/components/tableDiv.vue";
import bookinfoRepository from "../components/bookinfoRepository.vue";

export default {
	components: {
		formdrf,
		modal,
		tableDiv,
		tab,
		bookinfoRepository,
		formFilter,
		formItem
	},
	data() {
		return {
			setPriorityModalShow: false,
			setPriorityPk: 0,
			setPriorityData: {
				priority: 5
			},
			setPriorityModel: {
				priority: {
					label: "權重",
					type: "string"
				}
			},
			active_book_header: {
				ISBN: "ISBN",
				bookname: "書名",
				page: "頁數/總頁數",
				finish_part_count: "已完成段數",
				service_hours: "時數",
				priority: "權重",
				active_book_action: "動作"
			},
			inactive_book_header: {
				ISBN: "ISBN",
				bookname: "書名",
				owner: "上傳者",
				upload_date: "上傳日期",
				inactive_book_action: "動作"
			},
			search_book_header: {
				ISBN: "ISBN",
				bookname: "書名",
				page: "頁數/總頁數",
				finish_part_count: "已完成段數",
				service_hours: "時數",
				priority: "權重"
			},
			search_value: "",
			data: [
				{
					order: 0,
					display_name: "已校對",
					value: "finish",
					type: "bookinfo_repository_table",
					data: ""
				},
				{
					order: 1,
					display_name: "校對中",
					value: "active",
					type: "table",
					data: ""
				},
				{
					order: 2,
					display_name: "未審核",
					value: "inactive",
					type: "table",
					data: ""
				},
				{
					order: 3,
					display_name: "其他查詢",
					value: "search",
					type: "search",
					data: []
				}
			]
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	watch: {
		user() {
			this.get_table_data();
		}
	},
	mounted() {
		this.get_table_data();
	},
	methods: {
		get_table_data() {
			if (!this.user.id) {
				return false;
			}

			//finish book
			let query = { owner_id: this.user.id };
			bookInfoRest.filter(query).then(res => {
				let filter_data = [];
				_.each(res.data, v => {
					filter_data.push({
						ISBN: v["ISBN"],
						bookname: v["bookname"],
						bookbinding: v["bookbinding"],
						order: v["order"],
						author: v["author"],
						house: v["house"],
						date: v["date"],
						action: v["ISBN"]
					});
				});
				this.data[0].data = {};
				this.data[0].data["datas"] = filter_data;
			});
			//active book
			const c1 = bookRest.filter({ status: 1, owner_id: this.user.id });
			const c2 = bookRest.filter({ status: 2, owner_id: this.user.id });
			Promise.all([c1, c2]).then(res => {
				let book_data = [];
				book_data = book_data.concat(res[0].data);
				book_data = book_data.concat(res[1].data);
				let filter_data = [];
				_.each(book_data, v => {
					filter_data.push({
						ISBN: v.book_info.ISBN,
						bookname: v.book_info.bookname,
						page:
							v.finish_page_count.toString() + "/" + v.page_count.toString(),
						finish_part_count: v.finish_part_count,
						service_hours: v.service_hours,
						priority: v.priority,
						active_book_action: v
					});
				});
				this.data[1].data = {};
				this.data[1].data["header"] = this.active_book_header;
				this.data[1].data["datas"] = filter_data;
			});
			//inactive book
			query = { status: 0, owner_id: this.user.id };
			bookRest.filter(query).then(res => {
				let filter_data = [];
				_.each(res.data, v => {
					filter_data.push({
						ISBN: v.book_info.ISBN,
						bookname: v.book_info.bookname,
						owner: v.owner,
						upload_date: v.upload_date,
						inactive_book_action: v
					});
				});
				this.data[2].data = {};
				this.data[2].data["header"] = this.inactive_book_header;
				this.data[2].data["datas"] = filter_data;
			});
		},
		setPriority() {
			bookRest
				.partialupdate(this.setPriorityPk, { ...this.setPriorityData })
				.then(() => {
					this.setPriorityModalShow = false;
					this.get_table_data();
					this.$root.$message.open({ status: 200, message: "成功設定權重" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		book_del(pk) {
			this.$root.$confirm
				.open({ status: 200, message: `確認刪除書籍 id:${pk}` })
				.then(() => {
					return bookRest.delete(pk);
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: `成功刪除書籍 id:${pk}`
					});
					this.get_table_data();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		search() {
			let query = { bookname: this.search_value };
			bookRest.filter(query).then(res => {
				let filter_data = [];
				_.each(res.data, v => {
					filter_data.push({
						ISBN: v.book_info.ISBN,
						bookname: v.book_info.bookname,
						page:
							v.finish_page_count.toString() + "/" + v.page_count.toString(),
						finish_part_count: v.finish_part_count,
						service_hours: v.service_hours,
						priority: v.priority
					});
				});
				this.data[3].data = {};
				this.data[3].data["header"] = this.search_book_header;
				this.data[3].data["datas"] = filter_data;
				this.$root.$message.open({
					status: 200,
					message:
						"查詢完成，共取得 " + this.data[3].data["datas"].length + " 筆資料"
				});
			});
		}
	}
};
</script>
